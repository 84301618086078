import { NgModule } from '@angular/core';
import { SvgIcon } from 'app/components/util/ng2-svg-icon.component';
import { ScrollUpButtonComponent } from 'app/components/util/scroll-up-button.component';
import { CommonModule } from '@angular/common';
import { SafeHtmlPipe } from 'app/utils/safe-html.pipe';
import { SortByDirective, SortedDirective } from 'app/components/util/sort.directive';
import { EllipsisDirective } from 'app/components/util/ellipsis.directive';
import { ToggleButtonComponent } from 'app/components/form/toggle-button.component';
import { NgbAlertModule, NgbToastModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { HighlightPipe } from 'app/utils/highlight.pipe';
import { HasValidationErrorsDirective } from 'app/components/form/validation-errors.directive';
import { RomanizePipe } from 'app/components/util/romanize.filter';
import { CopyToCbComponent, CopyToCbDirective } from 'app/components/util/copy-to-cb.directive';
import { IsEventAdminDirective } from 'app/services/auth/is-event-admin.directive';
import { CapitalizeDirective } from 'app/components/util/capitalize.directive';
import { ClipboardModule } from 'ngx-clipboard';
import { JhiAlertComponent } from 'app/components/alert/alert.component';
import { IsClubUserDirective } from 'app/services/auth/is-club-user.directive';
import { IsAuthenticatedDirective } from 'app/services/auth/is-authenticated.directive';
import { StrictRangeDirective } from 'app/components/util/strict-range.directive';
import { AuthModule } from 'app/shared/auth.module';
import { GlobalToastsComponent } from 'app/shared/global-toasts.component';
import { TranslateModule } from '@ngx-translate/core';
import { SportSelectorComponent } from 'app/license/sport-selector.component';

@NgModule({
  imports: [
    CommonModule,
    NgbTooltipModule,
    ClipboardModule,
    NgbAlertModule,
    AuthModule,
    NgbToastModule,
    TranslateModule,
  ],
  declarations: [
    SvgIcon,
    ScrollUpButtonComponent,
    SafeHtmlPipe,
    SortedDirective,
    SortByDirective,
    EllipsisDirective,
    ToggleButtonComponent,
    HighlightPipe,
    HasValidationErrorsDirective,
    RomanizePipe,
    CopyToCbDirective,
    CopyToCbComponent,
    IsEventAdminDirective,
    CapitalizeDirective,
    IsClubUserDirective,
    IsAuthenticatedDirective,
    JhiAlertComponent,
    StrictRangeDirective,
    GlobalToastsComponent,
  ],
  exports: [
    SvgIcon,
    ScrollUpButtonComponent,
    SafeHtmlPipe,
    SortedDirective,
    SortByDirective,
    EllipsisDirective,
    ToggleButtonComponent,
    HighlightPipe,
    HasValidationErrorsDirective,
    RomanizePipe,
    CopyToCbDirective,
    CopyToCbComponent,
    IsEventAdminDirective,
    CapitalizeDirective,
    IsClubUserDirective,
    IsAuthenticatedDirective,
    JhiAlertComponent,
    StrictRangeDirective,
    AuthModule,
    GlobalToastsComponent,
  ],
  providers: [
    {
      provide: SortedDirective,
    },
  ],
})
export class SharedModule {}
