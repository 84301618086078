import { Directive, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { PrincipalService } from 'app/services/auth/principal.service';
import { Subscription } from 'rxjs';

@Directive({
  selector: '[hasAuthority], [hasAnyAuthority]',
})
export class HasAuthorityDirective implements OnInit, OnDestroy {
  @Input('hasAuthority') requiredAuthority: string;
  @Input('hasAnyAuthority') requiredAuthorities: string;
  subscription: Subscription;

  constructor(private elementRef: ElementRef, private principalService: PrincipalService) {}

  ngOnInit() {
    const self = this;
    this.subscription = this.principalService.getCurrentIdentity().subscribe({
      next() {
        self.updateVisibility();
      },
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  private updateVisibility() {
    const requiredAuthorities = this.requiredAuthorities
      ? this.requiredAuthorities.split(',').map((a) => a.trim())
      : [this.requiredAuthority];

    if (this.principalService.hasAnyAuthority(requiredAuthorities)) {
      this.elementRef.nativeElement.style.display = 'block';
    } else {
      this.elementRef.nativeElement.style.display = 'none';
    }
  }
}
