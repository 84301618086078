import { appState } from 'app/app.state';
import { homeState } from 'app/home/home.module';
import { entityState } from 'app/entities/entity.state';
import { ownEventsFutureState } from 'app/entities/event/event.state';
import { accountState } from 'app/account/account.state';
import { resetRequestState } from 'app/account/reset/request/reset.request.state';
import { resetFinishState } from 'app/account/reset/finish/reset.finish.state';

export const eventDetailsFutureState = {
  name: 'app.entity.event.**',
  url: '/event',
  loadChildren: () => {
    console.log('Lazy-Loading EventPageModule');
    return import('./pages/event/event-page.module').then((m) => m.EventPageModule);
  },
};

export const myClubFutureState = {
  name: 'app.my-club.**',
  url: '/my-club',
  loadChildren: () => {
    console.log('Lazy-Loading MyClubpageModule');
    return import('./pages/my-club/my-clubpage.module').then((m) => m.MyClubpageModule);
  },
};

export const regDetailsFutureState = {
  name: 'app.entity.registration-detail.**',
  url: '/registration/:id',
  loadChildren: () => {
    console.log('Lazy-Loading RegistrationDetailsPageModule');
    return import('./pages/registration-details/registration-details-page.module').then(
      (m) => m.RegistrationDetailsPageModule
    );
  },
};

export const plannedEventsFutureState = {
  name: 'app.entity.planned-event.**',
  url: '/planned-event',
  loadChildren: () => {
    console.log('Lazy-Loading PlannedEventsPageModule');
    return import('./pages/planned-event/planned-events-page.module').then((m) => m.PlannedEventsPageModule);
  },
};

export const usersFutureState = {
  name: 'app.entity.user.**',
  url: '/user',
  loadChildren: () => {
    return import('./pages/users/users-page.module').then((m) => m.UsersPageModule);
  },
};

export const licenseFutureState = {
  name: 'app.license.**',
  url: '/license',
  loadChildren: () => {
    return import('./features/licenses/license.module').then((m) => m.LicenseModule);
  },
};

export const settingsFutureState = {
  name: 'app.settings.**',
  url: '/settings',
  loadChildren: () => {
    return import('./settings/settings-page.module').then((m) => m.SettingsPageModule);
  },
};

export const resultsFutureState = {
  name: 'app.event_results.**',
  url: '/results/:eventId',
  loadChildren: () => {
    return import('./features/public-results/public-results.module').then((m) => m.PublicResultsModule);
  },
};

export const cupResultsFutureState = {
  name: 'app.cup-event-results.**',
  url: '/cup-results/:eventId',
  loadChildren: () => {
    return import('./features/public-results/public-results.module').then((m) => m.PublicResultsModule);
  },
};

export const publicEventFutureState = {
  name: 'app.entity.public-event-details.**',
  url: '/publicevent/:id',
  loadChildren: () => {
    return import('./features/registration/registration.module').then((m) => m.RegistrationModule);
  },
};

export const appRoutes = [
  appState,
  homeState,
  entityState,
  eventDetailsFutureState,
  myClubFutureState,
  regDetailsFutureState,
  ownEventsFutureState,
  plannedEventsFutureState,
  usersFutureState,
  licenseFutureState,
  settingsFutureState,
  resultsFutureState,
  cupResultsFutureState,
  publicEventFutureState,
  accountState,
  resetRequestState,
  resetFinishState,
];
