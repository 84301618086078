import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CupCategoryResults, CupEvent, CupEventStage } from 'app/cup-scores/model';
import { convertLocalDateFromServer } from 'app/components/util/date-util.service';
import { sortByAttrAsc } from 'app/services/utils.service';
import { firstValueFrom } from 'rxjs';

export type CupEventResultsResponse = Record<string, CupCategoryResults>;

@Injectable()
export class CupEventService {
  constructor(private http: HttpClient) {}

  getEvents(): Promise<CupEvent[]> {
    return firstValueFrom(this.http.get<CupEvent[]>('/api/public/cup-events')).then((events) =>
      events.map(convertEventFromServer)
    );
  }

  save(value: CupEvent): Promise<CupEvent> {
    return firstValueFrom(this.http.post<CupEvent>('/api/cup-events', value));
  }

  update(value: CupEvent): Promise<CupEvent> {
    return firstValueFrom(this.http.put<CupEvent>('/api/cup-events', value)).then(convertEventFromServer);
  }

  delete(id: number) {
    return firstValueFrom(this.http.delete(`/api/cup-events/${id}`));
  }

  getCupEventResults(id: number) {
    return firstValueFrom(this.http.get<CupEventResultsResponse>(`/api/public/cup-events/${id}/results`));
  }
}

function convertEventFromServer(event: CupEvent): CupEvent {
  event.stages = event.stages.map(convertEventStageFromServer);
  event.stages.sort(sortByAttrAsc('startDate'));
  return event;
}

function convertEventStageFromServer(stage: CupEventStage): CupEventStage {
  stage.startDate = convertLocalDateFromServer(stage.startDate);
  stage.endDate = convertLocalDateFromServer(stage.endDate);
  return stage;
}
