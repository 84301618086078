import { Directive, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Comparator, reversed, sortByMultipleValues, SortDirection } from 'app/services/utils.service';

export type Comparable = string | number | Date | undefined;

export type SortEvent = {
  direction: SortDirection;
  predicateId: string;
};

export type Predicate<T> = {
  id: string;
  getValue: (object: T) => Comparable;
  comparator?: Comparator<T>;
};

type SortField<T> = {
  predicate: Predicate<T>;
  direction: SortDirection;
  listener: SortStateListener;
};

interface SortStateListener {
  sortStateChanged(enabled: boolean, predicateId?: string, direction?: SortDirection): void;
}

@Directive({
  selector: '[sorted]',
})
export class SortedDirective<T> implements OnInit, OnChanges {
  @Input() multiValue: boolean = false;
  @Input('sorted') items: any[];
  @Input('sortEnabled') _sortEnabled: boolean = true;
  @Input() defaultSortPredicate: Predicate<T> | string;
  @Output() afterSort = new EventEmitter<SortEvent>();
  private sortFields: SortField<T>[] = [];
  private sortControlsByPredicateId: Record<string, SortStateListener> = {};

  get sortEnabled(): boolean {
    return this._sortEnabled;
  }

  ngOnInit() {
    if (this._sortEnabled) {
      this.sort();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes._sortEnabled !== undefined) {
      Object.values(this.sortControlsByPredicateId).forEach((listener) =>
        listener.sortStateChanged(
          changes._sortEnabled.currentValue,
          getPredicateId(this.defaultSortPredicate),
          SortDirection.ASC
        )
      );
    }
    if (changes._sortEnabled?.currentValue || (this._sortEnabled && changes.items?.currentValue)) {
      this.sort();
    }
  }

  sortBy(
    predicateOrPropName: string | Predicate<T>,
    direction: SortDirection | null,
    listener: SortStateListener
  ): void {
    const predicate =
      typeof predicateOrPropName === 'string' ? predicateFromPropName(predicateOrPropName) : predicateOrPropName;
    if (!this.multiValue) {
      const predicateChanged = this.sortFields.length && this.sortFields[0].predicate.id !== predicate.id;
      if (predicateChanged) {
        this.sortFields[0].listener.sortStateChanged(false);
      }
      this.sortFields = [{ predicate, direction, listener }];
    } else {
      const existingPredicateIndex = this.sortFields.findIndex((field) => field.predicate.id === predicate.id);
      if (!direction) {
        if (existingPredicateIndex >= 0) {
          this.sortFields.splice(existingPredicateIndex, 1);
        }
      } else {
        if (existingPredicateIndex >= 0) {
          const predicate = this.sortFields[existingPredicateIndex];
          predicate.direction = direction;
        } else {
          this.sortFields.push({ predicate, direction, listener });
        }
      }
    }
    this.sort();
  }

  registerSortControl(predicateId: string, listener: SortStateListener): void {
    this.sortControlsByPredicateId[predicateId] = listener;
  }

  private sort() {
    if (this.sortFields?.length) {
      sortByMultipleFields(this.items, this.sortFields);
      this.afterSort.emit({
        direction: this.sortFields[0]?.direction,
        predicateId: this.sortFields[0]?.predicate.id,
      });
    }
  }
}

const SORT_DIRECTION_ORDER: SortDirection[] = [SortDirection.ASC, SortDirection.DESC, null];
const SORT_DIRECTION_CLASSES: string[] = ['sorted-asc', 'sorted-desc', 'unsorted'];

@Directive({
  selector: '[sortBy]',
  exportAs: 'sortBy',
})
export class SortByDirective<T> implements OnInit, SortStateListener {
  private sortDirectionIndex: number = SORT_DIRECTION_CLASSES.indexOf('unsorted');
  private direction?: SortDirection = undefined;
  @Input('direction') directionInput?: SortDirection | string = null;
  @Input('sortBy') predicate: Predicate<T> | string;

  constructor(private el: ElementRef, private sortedDirective: SortedDirective<T>) {}

  ngOnInit() {
    const predicatedId = getPredicateId(this.predicate);
    this.sortedDirective.registerSortControl(predicatedId, this);
    this.el.nativeElement.onclick = () => {
      if (!this.sortedDirective.sortEnabled) {
        return;
      }
      this.toggleDirectionAndSort();
    };
    if (getPredicateId(this.sortedDirective.defaultSortPredicate) === predicatedId) {
      this.toggleDirectionAndSort();
    }
    this.updateUi();
  }

  activate(sortDirection?: SortDirection): void {
    this.direction = sortDirection ?? SortDirection.ASC;
    this.sortDirectionIndex = SORT_DIRECTION_ORDER.findIndex((v) => v === this.direction);
    this.sortedDirective.sortBy(this.predicate, this.direction, this);
  }

  private toggleDirectionAndSort(): void {
    if (this.direction === undefined) {
      this.direction =
        typeof this.directionInput === 'string'
          ? SortDirection[this.directionInput]
          : this.directionInput || SortDirection.ASC;
      this.sortDirectionIndex = SORT_DIRECTION_ORDER.findIndex((v) => v === this.direction);
    } else {
      this.sortDirectionIndex = (this.sortDirectionIndex + 1) % SORT_DIRECTION_ORDER.length;
      this.direction = SORT_DIRECTION_ORDER[this.sortDirectionIndex];
      if (!this.direction && !this.sortedDirective.multiValue) {
        this.sortDirectionIndex = (this.sortDirectionIndex + 1) % SORT_DIRECTION_ORDER.length;
        this.direction = SORT_DIRECTION_ORDER[this.sortDirectionIndex];
      }
    }
    if (this.sortedDirective.sortEnabled) {
      this.sortedDirective.sortBy(this.predicate, this.direction, this);
    }
    this.updateUi();
  }

  sortStateChanged(enabled: boolean, predicateId: string): void {
    if (!enabled) {
      this.direction = undefined;
      this.sortDirectionIndex = SORT_DIRECTION_ORDER.length - 1;
      this.updateUi();
    } else {
      if (getPredicateId(this.predicate) === predicateId) {
        this.toggleDirectionAndSort();
      } else {
        this.el.nativeElement.classList.remove(...SORT_DIRECTION_CLASSES);
        this.el.nativeElement.classList.add('unsorted');
      }
    }
  }

  private updateUi() {
    this.el.nativeElement.classList.remove(...SORT_DIRECTION_CLASSES);
    if (SORT_DIRECTION_CLASSES[this.sortDirectionIndex]) {
      this.el.nativeElement.classList.add(SORT_DIRECTION_CLASSES[this.sortDirectionIndex]);
    }
  }
}

function sortByMultipleFields<T>(elements: T[], sortFields: SortField<T>[]) {
  const fieldComparators: Comparator<T>[] = sortFields.map((field) => {
    if (field.predicate.comparator) {
      return field.direction === SortDirection.ASC ? field.predicate.comparator : reversed(field.predicate.comparator);
    }
    return (e1, e2) => {
      const v1 = field.predicate.getValue(e1);
      const v2 = field.predicate.getValue(e2);
      const directionMultiplier = field.direction === SortDirection.ASC ? 1 : -1;
      let result = 0;
      if ((v1 === undefined || v1 === null) && (v2 === undefined || v2 === null)) {
        return 0;
      }
      if (v1 === undefined || v1 === null) {
        return -1 * directionMultiplier;
      }
      if (v2 === undefined || v2 === null) {
        return 1 * directionMultiplier;
      }
      if (v1 < v2) result = -1 * directionMultiplier;
      if (v2 < v1) result = 1 * directionMultiplier;
      return result;
    };
  });
  sortByMultipleValues(elements, fieldComparators);
}

function predicateFromPropName<T>(propName: string): Predicate<T> {
  return {
    id: propName,
    getValue: (obj) => obj && obj[propName],
  };
}

function getPredicateId<T>(p?: Predicate<T> | string) {
  return typeof p === 'string' ? p : p?.id;
}
