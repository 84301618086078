import { Ng2StateDeclaration, UIRouterModule } from '@uirouter/angular';
import { TitleService } from 'app/services/title.service';
import { PrincipalService } from 'app/services/auth/principal.service';

export function createRouterModule(states: Ng2StateDeclaration[]) {
  return UIRouterModule.forRoot({
    states: [...states],
    useHash: true,
    otherwise: { state: 'app.home' },
    config: (router) => {
      router.transitionService.onSuccess({}, (transition) => {
        const titleService: TitleService = transition.injector().get(TitleService);
        const resolvedTitle = transition.injector().get('$pageTitle');
        if (resolvedTitle) {
          titleService.setTitle(resolvedTitle);
          return;
        }
        titleService.setTitle(transition.to().data?.pageTitle ?? 'global.title');
      });

      router.transitionService.onBefore({}, async (transition) => {
        const requiredAuthorities = transition.to().data?.authorities;
        if (requiredAuthorities) {
          const principalService: PrincipalService = transition.injector().get(PrincipalService);
          if (!principalService.isAuthenticated()) {
            await principalService.identity(true);
          }
          if (!principalService.hasAnyAuthority(requiredAuthorities)) {
            return transition.router.stateService.target('app.home');
          }
        }
      });
    },
  });
}
