import './polyfills';
import './components/alert/alert.component';

import { APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import '@angular/common/locales/global/fi';
import { BrowserModule } from '@angular/platform-browser';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ContentTypeInterceptor } from 'app/shared/content-type.interceptor';
import { QuillModule } from 'ngx-quill';
import { HomeModule } from 'app/home/home.module';
import { LoginModule } from 'app/features/login/login.module';
import { SortablejsModule } from 'nxt-sortablejs';
import { NotificationInterceptor } from 'app/blocks/interceptor/notification.interceptor';
import { SportService } from 'app/services/sport/sport.service';
import { KisanetAppComponent } from 'app/kisanet-app.component';
import { NavbarComponent } from 'app/layouts/navbar/navbar.component';
import { JhiLanguageComponent } from 'app/components/language/language.component';
import { CountryFlagComponent } from 'app/layouts/navbar/country-flag.component';
import { FooterComponent } from 'app/layouts/footer/footer.component';
import { NgbDropdownModule, NgbToastModule } from '@ng-bootstrap/ng-bootstrap';
import { PasswordResetModule } from 'app/account/reset/request/password-reset.module';
import { AuthErrorInterceptor } from 'app/blocks/interceptor/auth-error.interceptor';
import { AuthModule } from 'app/shared/auth.module';
import { CacheBusterInterceptor } from 'app/blocks/interceptor/cache-buster.interceptor';
import { TawkChatWidgetComponent } from 'app/components/chat/tawk-chat-widget.component';
import { PageRibbonComponent } from 'app/services/profiles/page-ribbon.component';
import { CookieWindowComponent } from 'app/components/cookie-window/cookie-window.component';
import Bugsnag from '@bugsnag/js';
import { BugsnagErrorHandler } from '@bugsnag/plugin-angular';
import { AppReloadToastComponent } from 'app/components/alert/app-reload-toast.component';
import { AppUpdateInterceptor } from 'app/blocks/interceptor/app-update.interceptor';
import { PrincipalService } from 'app/services/auth/principal.service';
import { AppReloadService } from 'app/services/app-reload.service';
import { datadogLogs } from '@datadog/browser-logs';
import { CoreModule } from 'app/core/core.module';
import { createRouterModule } from 'app/router-config';
import { appRoutes } from 'app/app-routes';

let principalService: PrincipalService;
let appUpdateInterceptor: AppUpdateInterceptor;

if (
  !window.location.host.toLowerCase().startsWith('xlocalhost') &&
  !window.location.host.toLowerCase().startsWith('x127.0.0.1')
) {
  Bugsnag.start({
    apiKey: '65acf816252018b44085520fa5ba998e',
    onError: (event) => {
      event.addMetadata('appVersion', { value: appUpdateInterceptor.getCurrentAppHash() || 'unknown' });
      if (principalService?.isAuthenticated()) {
        const identity = principalService.getIdentity();
        event.addMetadata('user', {
          login: identity?.login,
          orgName: identity?.organization?.name,
        });
      }
    },
  });
}

datadogLogs.init({
  clientToken: 'pube19cd7a87f92b9db4617efe4cfab6cd2',
  site: 'datadoghq.eu',
  forwardErrorsToLogs: true,
  sessionSampleRate: 100,
  service: 'kisanet-client',
  telemetrySampleRate: 0,
  env: window.location.host || 'unknown',
});

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}

function sportServiceInitializer(sportService: SportService) {
  return () => sportService.loadData();
}

function errorHandlerFactory(service: PrincipalService) {
  principalService = service;
  return new BugsnagErrorHandler();
}

function createAppUpdateInterceptor(reloadService: AppReloadService) {
  appUpdateInterceptor = new AppUpdateInterceptor(reloadService);
  return appUpdateInterceptor;
}

@NgModule({
  imports: [
    BrowserModule,
    HttpClientModule,
    createRouterModule(appRoutes),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    QuillModule.forRoot(),
    HomeModule,
    LoginModule,
    SortablejsModule.forRoot({ animation: 150 }),
    NgbDropdownModule,
    PasswordResetModule,
    AuthModule,
    NgbToastModule,
    CoreModule,
  ],
  providers: [
    {
      provide: LOCALE_ID,
      useValue: 'fi',
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ContentTypeInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: NotificationInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthErrorInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CacheBusterInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useFactory: createAppUpdateInterceptor,
      multi: true,
      deps: [AppReloadService],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: sportServiceInitializer,
      deps: [SportService],
      multi: true,
    },
    {
      provide: ErrorHandler,
      useFactory: errorHandlerFactory,
      deps: [PrincipalService],
    },
  ],
  declarations: [
    FooterComponent,
    CountryFlagComponent,
    JhiLanguageComponent,
    NavbarComponent,
    KisanetAppComponent,
    TawkChatWidgetComponent,
    PageRibbonComponent,
    CookieWindowComponent,
    AppReloadToastComponent,
  ],
  bootstrap: [KisanetAppComponent],
  exports: [CountryFlagComponent],
})
export class AppModule {
  constructor(private translateService: TranslateService) {
    this.translateService.use('fi');
  }
}

platformBrowserDynamic().bootstrapModule(AppModule);
